import React from "react"
import { useRouter } from "next/router"

// packages
import { t } from "i18next"

// component
import { Button, Card } from "@project/common"
import ManAndWomenApologize from "../../public/assets/ManAndWomenApologize"

// styles
import { FeatureNotAvailableInternalContainer } from "./FeatureNotAvailableInternal.styles"

export const FeatureNotAvailableInternal = (): JSX.Element => {
  const { push } = useRouter()
  return (
    <FeatureNotAvailableInternalContainer>
      <Card>
        <div className={"info-container"}>
          <ManAndWomenApologize />
          <h2 className={"main-title"}>{t("Feature Not Available")}</h2>
          <p className={"description"}>
            {t(
              "This feature isn't accessible now, but it's on its way in Version 2. Stay tuned for its arrival!",
            )}
          </p>
          <Button
            type={"default"}
            className={"back-btn"}
            shape={"round"}
            onClick={() => {
              push("/")
            }}
            btnText={t("Top page")}
          />
        </div>
      </Card>
    </FeatureNotAvailableInternalContainer>
  )
}
